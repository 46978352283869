const app = "@KF"
const module = "MRW"
const prefix = `${app}/${module}/`
export const MachinesRadiationalWatering = {
  LOAD_CONFIGURATIONS_REQUEST: prefix + 'LOAD_CONFIGURATIONS_REQUEST',
  LOAD_CONFIGURATIONS_SUCCESS: prefix + 'LOAD_CONFIGURATIONS_SUCCESS',
  LOAD_CONFIGURATIONS_FAILURE: prefix + 'LOAD_CONFIGURATIONS_FAILURE',
  SAVE_CONFIGURATIONS_REQUEST: prefix + 'SAVE_CONFIGURATIONS_REQUEST',
  SAVE_CONFIGURATIONS_SUCCESS: prefix + 'SAVE_CONFIGURATIONS_SUCCESS',
  SAVE_CONFIGURATIONS_FAILURE: prefix + 'SAVE_CONFIGURATIONS_FAILURE',
  POST_CONFIGURATIONS_REQUEST: prefix + 'POST_CONFIGURATIONS_REQUEST',
  POST_CONFIGURATIONS_SUCCESS: prefix + 'POST_CONFIGURATIONS_SUCCESS',
  POST_CONFIGURATIONS_FAILURE: prefix + 'POST_CONFIGURATIONS_FAILURE',
  PUT_CONFIGURATIONS_REQUEST: prefix + 'PUT_CONFIGURATIONS_REQUEST',
  PUT_CONFIGURATIONS_SUCCESS: prefix + 'PUT_CONFIGURATIONS_SUCCESS',
  PUT_CONFIGURATIONS_FAILURE: prefix + 'PUT_CONFIGURATIONS_FAILURE',
  DELETE_CONFIGURATIONS_REQUEST: prefix + 'DELETE_CONFIGURATIONS_REQUEST',
  DELETE_CONFIGURATIONS_SUCCESS: prefix + 'DELETE_CONFIGURATIONS_SUCCESS',
  DELETE_CONFIGURATIONS_FAILURE: prefix + 'DELETE_CONFIGURATIONS_FAILURE',
  ADD_CONFIGURATION: prefix + 'ADD_CONFIGURATION',
  REMOVE_CONFIGURATION: prefix + 'REMOVE_CONFIGURATION',
  UPDATE_CONFIGURATION: prefix + 'UPDATE_CONFIGURATION',
}
