export const Me = {
  CONFIRM_REQUEST: 'CONFIRM_REQUEST',
  CONFIRM_FAILURE: 'CONFIRM_FAILURE',
  CONFIRM_SUCCESS: 'CONFIRM_SUCCESS',
  GET_CURRENT: 'GET_CURRENT',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGOUT: 'LOGOUT',
  RENAME: 'RENAME',
  SIGN_UP_REQUEST: 'SIGN_UP_REQUEST',
  SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
}
