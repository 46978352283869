const app = "@KF"
const module = "SUZU"
const prefix = `${app}/${module}/`
export const Suzu = {
  LOAD_REQUEST: prefix + 'LOAD_REQUEST',
  LOAD_SUCCESS: prefix + 'LOAD_SUCCESS',
  LOAD_FAILURE: prefix + 'LOAD_FAILURE',
  UPDATE_DEVICE_MONITOR: prefix + 'UPDATE_DEVICE_MONITOR',
  SAVE_DEVICE_MONITOR_REQUEST: prefix + 'SAVE_DEVICE_MONITOR_REQUEST',
  SAVE_DEVICE_MONITOR_SUCCESS: prefix + 'SAVE_DEVICE_MONITOR_SUCCESS',
  SAVE_DEVICE_MONITOR_FAILURE: prefix + 'SAVE_DEVICE_MONITOR_FAILURE',
  POST_DEVICE_MONITOR_REQUEST: prefix + 'POST_DEVICE_MONITOR_REQUEST',
  POST_DEVICE_MONITOR_SUCCESS: prefix + 'POST_DEVICE_MONITOR_SUCCESS',
  POST_DEVICE_MONITOR_FAILURE: prefix + 'POST_DEVICE_MONITOR_FAILURE',
  PUT_DEVICE_MONITOR_REQUEST: prefix + 'PUT_DEVICE_MONITOR_REQUEST',
  PUT_DEVICE_MONITOR_SUCCESS: prefix + 'PUT_DEVICE_MONITOR_SUCCESS',
  PUT_DEVICE_MONITOR_FAILURE: prefix + 'PUT_DEVICE_MONITOR_FAILURE',
}
